import '../styles/base/_body.scss'
import type { AppProps } from 'next/app'
import initAuth from '../utils/initAuth'

initAuth()

function WepPayment({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />
}

export default WepPayment
